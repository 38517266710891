#modal_header {
  display: flex;
  padding: 20px;
}

.modal_form_field1,
.modal_form_field2 {
  display: flex;
  flex-direction: column;
}

.modal_form_field1 > input,
.modal_form_field2 > input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #d1d5e0;
}

.modal_form_field1 > label,
.modal_form_field2 > label {
  font-weight: bold;
  padding-top: 10px;
}

.editor{
  font-family: sans-serif;
  text-align: center;
}

.modal_sign_in_btn_and_forget_pw {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid rgb(219, 216, 216);
  
}

.modal_sign_in_btn_and_forget_pw2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  
}
.modal_sign_in_btn_and_forget_pw3 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  
}

.modal_sign_in_btn_and_forget_pw > button {
  width: 25%;
  outline: none;
  border-radius: 5px;
  border: none;
  background-color: #3498db;
  padding: 5px;
  color: #fff;
  font-weight: bold;
  margin: 20px 0 20px 0;
}
.modal_sign_in_btn_and_forget_pw >a {
  padding-left: 20px;
}
#modal_footer {
  display: flex;
  justify-content: center;
}

.modal_footer_left {
  display: flex;
  justify-content: inherit;
  width: 50%;
  /* padding-left: 10px; */
}

.here-p{
  padding: 5px;
  cursor: pointer;
  color: #3498db;
  text-decoration: underline;
}
.here-p2{
  padding: 5px;
  cursor: pointer;
 
}

.eye-icon{
  font-size: 25px;
  position: relative;
  top: -30px;
  left: 93%;
  cursor: pointer;
}

.social-btn{
  background-color: #dd4b39;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px;
  color: white;
  width: 30px;
  margin: 10px;
}

.social-btn-twitter{
  background-color: #1771ed;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px;
  color: white;
  width: 30px;
  margin: 10px;
}

.kep-login-facebook{
  width: 30px !important;
  height: 35px !important;
  border-radius: 7px !important;
}
.kep-login-facebook.small {
  padding: calc(0.34435vw + -1.61157px) calc(0.34435vw + 1.38843px) !important;
}

.kep-login-facebook {
  font-weight: 500 !important;
  font-size: calc(.27548vw + 11.71074px) !important;
}