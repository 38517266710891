* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact_main_container {
  border-left: 1px solid #d1d5e0;
}

.contact_topbar {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  flex-wrap: wrap;
}

.contact_topbar > h2 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  color: #faaf18;
}

.contact_searchbar > input[type="search"] {
  padding-left: 5px;
  border: 1px solid #d1d5e0;
  border-radius: 3px;
  outline: none;
  color: #6f7d95;
}

#contact_search_icon {
  margin-left: -30px;
}

.contact_cards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 10px;
}

.contact_cards_row1,
.contact_cards_row2 {
  display: flex;
  justify-content: center;
  margin: 10px;
  flex-wrap: wrap;
}

.contact_card {
  box-shadow: 0px 0px 5px 0px #dcdcdc;
  padding: 20px 0 20px 20px;
  width: 40%;
  margin: 10px;
  border-radius: 5px;
}
.contact_card > h2 {
  color: #00496b;
  font-family: "Roboto", Sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.location,
.mobile,
.email {
  display: flex;
  align-items: center;
}
.location > span,
.mobile > span,
.email > span {
  padding-left: 10px;
  font-family: "Roboto", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 35px;
  color: #54595f;
}

.contact_form {
  padding: 30px 0 0 100px;
}

.contact_form_heading > h5 {
  border-bottom: 2px solid #faaf18;
  width: 88%;
  padding-bottom: 3px;
  color: #4d5a60;
  font-family: "Roboto", Sans-serif;
  font-size: 19px;
  font-weight: 700;
  stroke: #000;
}

.contact_form_col1_fields,
.contact_form_col2_fields {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 48%;
  padding: 20px;
}

.contact_form_fields {
  display: flex;
  width: 100%;
}

.contact_form_col1_fields > label {
  padding: 20px 0 5px 0;
  font-size: 18px;
  color: #6f7d95;
}

.contact_form_col2_fields > label {
  margin-left: 20px;
  padding: 20px 0 5px 0;
  font-size: 18px;
  color: #6f7d95;
}

.contact_form_col1_fields > input {
  padding: 20px;
  padding: 11px 50px 5px 10px;
  border: 1px solid #d1d5e0;
}
.contact_form_col2_fields > input {
  margin-left: 20px;
  padding: 8px 70px 5px 10px;
  border: 1px solid #d1d5e0;
}

.contact_messege_and_submit {
  display: flex;
  flex-direction: column;
  padding: 5px 0 50px 20px;
}

.contact_messege_and_submit > label {
  font-size: 18px;
  color: #6f7d95;
}

.contact_messege_and_submit > textarea {
  width: 98%;
  border: 1px solid #d1d5e0;
  outline: none;
}

.contact_messege_and_submit > button {
  width: 150px;
  color: #fff;
  margin-top: 20px;
  outline: none;
  background-color: #00496b;
  border: none;
  padding: 10px 30px 10px 30px;
  display: block;
  text-align: center;
}
/******* media query ********/
@media only screen and (min-width: 250px) and (max-width: 767px) {
  .contact_cards {
    flex-direction: column;
  }
  .contact_card {
    width: 90%;
  }
  .contact_cards_row1,
  .contact_cards_row2 {
    flex-direction: column;
    justify-content: center;
  }

  .contact_topbar {
    flex-direction: column;
    align-items: center;
  }

  .contact_form_fields {
    flex-direction: column;
  }

  .contact_form {
    padding-left: 50px;
  }

  .contact_form_col2_fields > input {
    margin-left: 0;
    width: 80%;
    border: 1px solid #6f7d95;
  }

  .contact_form_col1_fields > input {
    margin-left: 0;
    width: 100%;
    border: 1px solid #6f7d95;
  }

  .contact_form_col2_fields > label {
    margin: 0;
  }

  .contact_slot_opt > strong {
    font-size: 12px;
  }

  .contact_messege_and_submit > textarea {
    width: 80%;
  }

  .contact_messege_and_submit > button {
    width: 80%;
  }

  .location > span,
  .mobile > span,
  .email > span {
    font-size: 10px;
  }
}
