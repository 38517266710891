* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.all_answers_main_container {
  border-left: 1px solid #c8c9ca;
  /* height: 100%; */
}

.all_answers_topbar {
  width: 100%;
  display: flex;
  justify-content:flex-start;
  align-items: baseline;
  padding-top: 10px;
  /* background-color: red; */
}

.all_answers_topbar > span {
  font-size: 16px;
  color: #56af3e;
  margin: 0px 10px;
  width: 13%;
  text-align: center;
  padding-left: 39px;
  
}

.all_answers_topbar > h1 {
  font-size: 22px;
  color: #3498db;
  margin: 0 0 15px 0;
  word-wrap: break-word;
  margin: 0px 20px;
}

.ques_display_section {
  display: flex;
  flex-direction: row;
  /* justify-content: space-; */
  padding-left: 50px;
  height: 100%;
}

.ques_display_left_section > ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ques_display_left_section > ul > li {
  margin-bottom: 10px;
}
.ques_crud_opr_icons {
  display: flex;
  justify-content: end;
  padding-bottom: 40px;
}

.ques_display_right_section {
  display: flex;
  flex-direction: column;
  justify-content: inherit;
  /* height: 60vh; */
  padding-left: 50px;
  margin-bottom: 20px;
}

.ques_asked_by {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px;
}

.ques_asked_by_left > small {
  padding-left: 1px;
}

.ques_asked_by_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ques_asked_by_right > small {
  padding-left: 30px;
}

.all_answers_nav {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  border-bottom: 1px solid #c8c9ca;
}

.all_answers_rigth_nav {
  display: flex;
}
.all_answers_rigth_nav > p {
  padding-right: 20px;
}

.answer_explained_header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 15%;
  padding: 20px 0 0 53px;
  padding: 10px 0px;
  justify-content: center;
}

.answer_display_left_section > ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.answer_display_left_section > ul > li {
  margin-bottom: 10px;
}
.answer_box{
}
.default_tag{
  background-color: rgb(83, 183, 216);
  color: white;
  padding: 2px;
  border-radius: 2px;
  text-align: center;
  margin: 0px 8px;
  font-size: 11px;
}

.ques_display_right_section1 {
}
#post_solution{
  padding: 13px;
}
.postanswer_btn{
  margin: 10px 0px;
}
.answer_describe {
  display: flex;
  justify-content: center;
  padding: 38px 0px 0px 0px;
}

.answer_describe > p {
  width: 100%;
  text-align: justify;
padding: 0px 40px 30px 30px;
}

.post_ques_solution {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.post_ques_solution > form > button {
  background-color: #e65c23;
  color: #fff;
  padding: 5px 20px 5px 20px;
  outline: none;
  border: none;
  border-radius: 3px;
}

.post_ques_solution > form > textarea {
  border: 1px solid #c8c9ca;
}

.post_ques_solution > p {
  padding: 10px 0 0 0;
}
/* .ques_crud_opr_icons {
  display: flex;
  justify-content: end;
} */

/*  
.ques_asked_by {
  display: flex;
  justify-content: left;
} */
/* 
.ques_display_section {
  display: flex;
  flex-direction: column;
  border: 1px solid;
}

.ques_display_left_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid;
}

.ques_display_right_section {
  display: flex;
  border: 1px solid;
}

.ques_display_right_section {
  display: flex;
  flex-direction: column;
}

.ques_crud_opr_icons {
  display: flex;
  justify-content: end;
}

.ques_asked_by {
  display: flex;
}

.ques_asked_by_left {
  display: flex;
}

.ques_asked_by_right {
  display: flex;
} */
