.service_page{
    border: 1px solid rgb(206, 200, 200);
    padding: 10px;
}

.main_services{
    display: flex;
}
.icons{
    font-size: 65px;
    padding: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #56af3e;
    margin: 20px;
    cursor: pointer;
}

.icons2{
    font-size: 65px;
    padding: 20px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #00adef;
    margin: 20px;
    cursor: pointer;
}

.circle{
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.circle2{
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}