* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Lato"; */
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

.dropdown-toggle {
  white-space: nowrap;
  background-color: #00496b;
  border: none;
}

.dropdown-toggle:hover {
  white-space: nowrap;
  background-color: #00496b;
  border: none;
  outline: none !important;
}
.dropdown-toggle:active {
  white-space: nowrap;
  background-color: #00496b;
  border: none;
  outline: none !important;
}
button:focus:not(:focus-visible) {
  outline: 0;
  background: #00496b !important;
  border: none;
  outline: none;
  box-shadow: none !important;
}
.btn-check:active + .btn, .btn-check:checked + .btn, .btn.active, .btn.show, .btn:active {
  color: var(--bs-btn-active-color);
  background-color: #00496b !important;
  border-color: #00496b !important;
}


#header {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgb(0, 73, 107);
  padding: 15px 15px 10px 25px;
  position: sticky;
  top: 0;
  z-index: 1;
}
.user-name{
  font-size: 21px !important;
}
.logout-icon{
  font-size: 21px;
  cursor: pointer;
}
.header_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.responsive_icons {
  display: none;
  cursor: pointer;
}

.header_left input[type="search"] {
  width: 350px;
  height: 38px;
  margin-left: 20px;
  border-radius: 5px;
  padding-left: 15px;
  outline: none;
  color: #8c97b2;
  border: 1px solid rgba(140, 151, 178, 0.4);
  background-color: #fff;
  transition: all 0.3s ease;
}

#search_icon {
  margin-left: -30px;
  color: #8c97b2;
  font-size: 18px;
  font-weight: bolder;
}
/* .header_left input[type="search"]:hover {
  width: 400px;
} */

.header_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffff;
  font-size: 15px;
}

.header_list_2 {
  margin-right: 55px;
}

.header_list_2 > ul {
  display: flex;
  flex-wrap: wrap;
  padding-top: 17px;
}

.header_list_2 > ul > li {
  margin-right: 25px;
  font-size: 16px;
  line-height: 1.42857143;
  font-weight: normal;
  color: #fff;
}

.toggle > span {
  font-size: 16px;
  margin-right: 20px;
  font-weight: normal;
  cursor: pointer;
}

/********* media query  *********/
@media only screen and (min-width: 250px) and (max-width: 320px) {
  .pointer {
    padding-top: 20px;
  }

  #header {
    width: 100vw !important;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .header_left {
    flex-direction: column;
  }

  .header_left > img {
    display: none;
  }

  .responsive_icons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 15px;
  }

  #ques_icon,
  #rightSide_icon,
  #hamburger_icon {
    color: #fff;
  }
  .header_left input[type="search"] {
    width: 100%;
  }
  .header_left input[type="search"]:hover {
    width: 100%;
  }
  .searchbar {
    display: flex;
    align-items: center;
    margin-left: -30px;
  }
  .searchbar > #search_icon {
    margin-left: -30px;
  }

  .header_right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header_list1 > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
  }
  .header_list1 > ul > li {
    font-size: 12px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .header_list_2 {
    display: none;
  }

  .toggle > span {
    display: none;
  }
}

@media only screen and (min-width: 321px) and (max-width: 376px) {
  .pointer {
    padding-top: 20px;
  }

  #header {
    width: 100vw;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .header_left {
    flex-direction: column;
  }

  .header_left > img {
    display: none;
  }

  .responsive_icons {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    padding: 0 20px 10px 20px;
  }

  #ques_icon,
  #rightSide_icon,
  #hamburger_icon {
    color: #fff;
  }

  .header_left input[type="search"] {
    width: 100%;
    padding-right: 70px;
    margin: 0;
  }

  .searchbar {
    display: flex;
    align-items: center;
  }

  .header_right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header_list1 > ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px;
  }
  .header_list1 > ul > li {
    font-size: 12px;
    padding-right: 12px;
    padding-bottom: 10px;
  }
  .header_list_2 {
    display: none;
  }

  .toggle > span {
    display: none;
  }
}

@media only screen and (min-width: 377px) and (max-width: 767px) {
  .pointer {
    padding-top: 20px;
  }

  #header {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .header_left {
    flex-direction: column;
  }

  .header_left > img {
    display: none;
  }

  .responsive_icons {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    padding: 0 20px 10px 20px;
  }

  #ques_icon,
  #rightSide_icon,
  #hamburger_icon {
    color: #fff;
  }

  .header_left input[type="search"] {
    width: 100%;
    padding-right: 70px;
    margin: 0;
  }

  .searchbar {
    display: flex;
    align-items: center;
  }

  .header_right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header_list1 > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
  }
  .header_list1 > ul > li {
    font-size: 12px;
    padding-right: 24px;
    padding-bottom: 10px;
  }
  .header_list_2 {
    display: none;
  }

  .toggle > span {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  #header {
    flex-wrap: wrap;
  }
}
