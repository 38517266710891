.title{
    font-size: 28px;
    font-weight: bold;
    padding: 5px 0px;
}
.price{
    font-size: 23px;
    font-weight: bold;
    padding: 5px 0px;
    color: #faaf18;
}


.wrapper{
    padding: 10px;
    margin: 20px;
    width: 30%;
    text-align: center;
    border: 1px solid black;
    border-radius: 5px;
}
.product-price-btn button{
    border: none;
    outline: none;
    background-color: #00496b;
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0px;
}

.main-paypal{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.status{
    background-color: green;
    padding: 3px;
    border-radius: 5px;
    color: white;
}
.okay-payment-button{
    border: none;
    outline: none;
    background-color: #00496b;
    color: white;
    padding: 10px;
    border-radius: 5px;
    width: 200px;
}

.okay-payment-button:hover{
    background-color: #00496b !important;
}