* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

.banner_image{
  height: 650px;
}
/************ media query  ***********/

@media only screen and (min-width: 250px) and (max-width: 320px) {
  #carousel {
   
  }
}

@media only screen and (min-width: 321px) and (max-width: 376px) {
  #carousel {
   
  }
}

@media only screen and (min-width: 377px) and (max-width: 767px) {
  #carousel {
   
  }
}

@media only screen and (min-width: 426px) and (max-width: 520px) {
  #carousel {
   
  }
}

@media only screen and (min-width: 521px) and (max-width: 767px) {
  #carousel {
  
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #carousel {
   
  }
} 
 
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  #carousel {
   
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  #carousel {
   
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1440px) {
  #carousel {
   
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1880px) {
  #carousel {
   
  }
}

@media only screen and (min-width: 1881px) and (max-width: 2520px) {
  #carousel {
   
  }
}

