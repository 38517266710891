.main_forget{
    text-align: center;
    padding: 20px 0px;
}
#modal_header {
    display: flex;
    padding: 20px;
  }
  
  .input_tag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .input_tag > input {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #d1d5e0;
    width: 50%;
    margin: 20px 0px;
  }
  
  .input_tag > label {
    font-weight: bold;
    padding-top: 10px;
  }

  .input_tag > button {
    background-color: #00496b;
    color: #fff;
    padding: 5px;
    border: none;
    outline: none;
    border-radius: 5px;
    width: 120px;
  }

  

 