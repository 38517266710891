* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.advertise_main_container {
  border-left: 1px solid #d1d5e0;
}

.advertise_topbar {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  flex-wrap: wrap;
}

.advertise_topbar > h2 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  color: #faaf18;
}

.advertise_searchbar > input[type="search"] {
  padding-left: 5px;
  border: 1px solid #d1d5e0;
  border-radius: 3px;
  outline: none;
  color: #6f7d95;
}

#advertise_search_icon {
  margin-left: -30px;
}

.advertise__front_desc_para > p {
  text-align: justify;
  color: #5f6f81;
  font-family: "Roboto", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding: 0 50px 0 50px;
}

.audience_profile > h2 {
  color: #00496b;
  font-family: "Roboto", Sans-serif;
  font-size: 21px;
  font-weight: 600;
  padding: 20px 50px 0 50px;
}

.audience_profile > p {
  text-align: justify;
  color: #5f6f81;
  font-family: "Roboto", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  padding: 0 50px 0 50px;
}

.advert_slot_opt > h2 {
  color: #00496b;
  font-family: "Roboto", Sans-serif;
  font-size: 21px;
  font-weight: 600;
  padding: 20px 50px 0 50px;
}

.advert_slot_opt > strong {
  color: #434e5b;
  font-family: "Roboto", Sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding: 0 50px 20px 50px;
  display: block;
}

.advertise_form {
  padding: 30px 0 0 50px;
}

.adv_form_heading > h5 {
  border-bottom: 2px solid #faaf18;
  width: 80%;
  padding-bottom: 3px;
  color: #4d5a60;
  font-family: "Roboto", Sans-serif;
  font-size: 19px;
  font-weight: 700;
  stroke: #000;
}

.adv_form_col1_fields,
.adv_form_col2_fields {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 48%;
}

.adv_form_fields {
  display: flex;
  width: 100%;
}

.adv_form_col1_fields > label {
  padding: 20px 0 5px 0;
  font-size: 18px;
  color: #6f7d95;
}

.adv_form_col2_fields > label {
  margin-left: 20px;
  padding: 20px 0 5px 0;
  font-size: 18px;
  color: #6f7d95;
}

.adv_form_col1_fields > input {
  padding: 20px;
  padding: 9px 50px 5px 10px;
  border: 1px solid #d1d5e0;
}
.adv_form_col2_fields > input {
  margin-left: 20px;
  padding: 8px 70px 5px 10px;
  border: 1px solid #d1d5e0;
}

.messege_and_submit {
  display: flex;
  flex-direction: column;
  padding: 20px 0 50px 0;
}

.messege_and_submit > label {
  font-size: 18px;
  color: #6f7d95;
}

.messege_and_submit > textarea {
  width: 96%;
  border: 1px solid #d1d5e0;
  outline: none;
}

.messege_and_submit > button {
  width: 150px;
  color: #fff;
  margin-top: 20px;
  outline: none;
  background-color: #00496b;
  border: none;
  padding: 10px 30px 10px 30px;
  display: block;
  text-align: center;
}

/************* media query ******************/

@media only screen and (min-width: 250px) and (max-width: 767px) {
  .advertise_topbar {
    flex-direction: column;
    align-items: center;
  }

  .adv_form_fields {
    flex-direction: column;
  }

  .advertise_form {
    padding-left: 50px;
  }

  .adv_form_col2_fields > input {
    margin-left: 0;
    width: 80%;
    border: 1px solid #6f7d95;
  }

  .adv_form_col1_fields > input {
    margin-left: 0;
    width: 80%;
    border: 1px solid #6f7d95;
  }

  .adv_form_col2_fields > label {
    margin: 0;
  }

  .advert_slot_opt > strong {
    font-size: 12px;
  }

  .messege_and_submit > textarea {
    width: 80%;
  }

  .messege_and_submit > button {
    width: 80%;
  }
}
