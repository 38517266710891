.heading_profile{
    padding: 20px;
    border: 1px solid rgb(226, 223, 223);
    display: flex;
    justify-content: space-between;
}
.edit_profile{
    color: rgb(8, 179, 236);
    cursor: pointer;
}

.third{
    padding: 50px 57px;
    margin: 0px;
}
.profile_img{
    width:70px;
    height: 70px;
    border-radius: 50%;
}
.details_user{
    line-height: 1px;
}
.details_user p{
    line-height: 1px;
}

.first{
    padding: 50px 57px;
    margin: 0px;
}

.second{
    padding: 50px 20px;
    margin: 0px;
  
}
.details_user2{
    line-height: 0.5px;
    padding: 0px 10px;
}
.details_user3{
    line-height: 0.5px;
    padding: 10px 10px;
}

.modal_first_profile{
    display: flex;
    padding: 10px 20px;
    justify-content: space-around;
}
.modal_first_profile input{
    padding: 20px 0px;
    color: rgb(8, 179, 236);
    cursor: pointer;
}


.show_more{
    
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}


.modal_first_profile1 > label {
    padding: 20px 0 5px 0;
    font-size: 18px;
    color: #6f7d95;
  }
  
  .contact_form_col2_fields > label {
    margin-left: 20px;
    padding: 20px 0 5px 0;
    font-size: 18px;
    color: #6f7d95;
  }
  
  .modal_first_profile1 > input {
    padding: 20px;
    padding: 5px 50px 5px 10px;
    border: 1px solid #d1d5e0;
  }
  .contact_form_col2_fields > input {
    margin-left: 20px;
    padding: 8px 70px 5px 10px;
    border: 1px solid #d1d5e0;
  }
  .modal_first_profile1,
.contact_form_col2_fields {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
}

.update_profile_btn{
    background-color: #3498db;
    padding: 15x;
    border: none;
    outline: none;
    color: white;
    height: 40px;
    width: 200px;
    margin-left: 20px;
}