* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ques_by_level_main_container {
  border-left: 1px solid #d1d5e0;
}

.ques_by_level_topbar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d1d5e0;
  padding: 20px 20px 10px 30px;
  flex-wrap: wrap;
}

.ques_by_level_topbar > h2 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  color: #faaf18;
}

.ques_by_level_searchbar > input[type="search"] {
  padding-left: 5px;
  border: 1px solid #d1d5e0;
  border-radius: 3px;
  outline: none;
  color: #6f7d95;
  width: 300px;
  padding: 8px;
}

#ques_by_level_search_icon {
  margin-left: -30px;
}

.ques_by_level_buttons > .button_collections {
  /* padding: 50px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.button_collections > button {
  outline: none;
  padding: 10px;
  width: 200px;
  border: 2px solid #00496b;
  border-radius: 5px;
  background-color: #fff;
  font-size: 18px;
  color: #00496b;
  font-weight: 600;
  margin: 20px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_collections > button:hover {
  border: 2px solid #e65c23;
  color: #e65c23;
  width: 200px;
}

@media only screen and (min-width: 250px) and (max-width: 767px) {
  .ques_by_level_topbar {
    flex-direction: column;
    align-items: center;
  }
  .ques_by_level_buttons {
    padding: 0;
  }
}
