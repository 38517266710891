.cat-list-notes {
    border: 1px solid #0c2342;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
}
.download-btn-note{
    background-color: #0c2342;
    color: white;
    border: none;
    outline: none;
    padding: 10px;
    margin: 20px;
    border-radius: 5px;
}