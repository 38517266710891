* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.containers {
  border-left: 1px solid #d1d5e0;
  border-right: 1px solid #d1d5e0;
}
.all_que_topbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-top: 20px; */
}
.all_que_topbar > h5 {
  padding-top: 15px;
  font-weight: 550;
}
#label1 {
  color: #748398;
  width: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

#dropdown2,
#dropdown1 {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
  padding: 10px 40px 10px 5px;
  color: #8b97b1;
  background-color: #f4f5f9;
  border-radius: 3px;
  outline: none;
  border: 1px solid #d1d5e0;
  cursor: pointer;
}

.dropdowns {
  /* width: 50%; */
  padding-left: 85px;
  display: flex;
  flex-wrap: wrap;
}

.filter_cat{
  display: flex;
}

.mid_container_nav {
  border: 1px solid #d1d5e0;
  border-left: none;
  border-right: none;
  margin-top: 15px;
  margin-left: -12px;
}

#mid_nav {
  display: flex;
  justify-content: space-between;
}

.left_nav > ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  margin-left: -20px;
  padding-top: 10px;
}

.left_nav > ul > li > .active {
  border-bottom: 4px solid #f39c12;
}

.left_nav > ul > li {
  padding-right: 30px;
  color: #b3a5b5;
}

.left_nav > ul > li > a {
  font-size: 14px;
  display: block;
  line-height: 40px;
  transition: all 0.3s ease;
  color: #8c97b2;
  cursor: pointer;
}

.left_nav > ul > li > a:hover {
  border-bottom: 4px solid #f39c12;
  transition: all 0.3s ease;
}

.right_nav {
  padding: 10px 20px 0 0;
}

#right_nav_label {
  color: #748398;
  padding-right: 10px;
}

#nav_dropdown {
  padding: 5px 20px 5px 20px;
  color: #8b97b1;
  background-color: #f4f5f9;
  border-radius: 3px;
  outline: none;
  border: 1px solid #d1d5e0;
  cursor: pointer;
}

.card_page_number {
  display: flex;
  justify-content: end;
  padding: 10px 0 0 0;
}

.card_page_number > ul {
  display: flex;
  cursor: pointer;
}

.page-numbers > li {
  border: 1px solid #808ca2;
  border-radius: 3px;
  padding: 0 10px 0 10px;
  color: #808ca2;
  margin-right: 5px;
}

.page-numbers > li:hover {
  background-color: #e65c23;
  color: #fff;
}

.page-numbers > li:nth-child(1) {
  border: 1px solid #808ca2;
  padding: 0 10px 0 10px;
  background-color: #e65c23;
  color: #fff;
}
/***************** media query  *************/

@media only screen and (min-width: 250px) and (max-width: 892px) {
  .all_que_topbar {
    display: flex;
    flex-direction: column;
  }
  .mid_container_nav {
    /* width: 100vw; */
    margin-right: 0;
  }
  #mid_nav {
    /* background-color: #00496b; */
    justify-content: center;
  }

  .left_nav > ul > li > a {
    color: #000;
  }

  .dropdowns {
    flex-direction: column;
    padding: 0;
  }
  #all_que_form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  #dropdown1,
  #dropdown2 {
    margin: 10px 0 0 0;
    width: 90%;
  }
  .right_nav {
    display: none;
  }
}
