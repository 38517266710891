#dropdown1 {
    display: inline-block;
    margin-left: 0px !important;
    margin: 10px 0px !important;
    padding: 10px 40px 10px 5px;
    color: #8b97b1;
    background-color: #fff !important;
    border-radius: 3px;
    outline: none;
    border: 1px solid #d1d5e0;
    cursor: pointer;
    width: 100% !important;
  }
  
  .submit-question-btn{
    background-color: #e65c23!important;
    border: none;
    outline: none;
    color: #fff;
    padding: 10px;
  }
  .policy{
    font-style: italic;
    line-height: 1;
    font-family: "Lato" !important;
    margin-top: 10px;
  }
  .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: var(--ck-color-base-border);
    height: 215px !important;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: var(--ck-color-base-border);
    height: 215px !important;
}
.ck.ck-editor__editable_inline > :first-child {
    margin-top: var(--ck-spacing-large);
    height: 215px !important;
  }