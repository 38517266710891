* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}
body {
  font-family: 'Lato', sans-serif;
}

.front {
  display: flex !important;
}

.mid_sections {
  width: 100%;
}

@media only screen and (min-width: 250px) and (max-width: 320px) {
}
